import React from 'react';
import { Link } from 'react-router-dom';
import { Layout, Header, Navigation, Drawer, Content } from 'react-mdl';
import Main from './components/Main';
import './App.css';
import logo from './femi-anjorin-logo-black.png';
import logoWhite from './femi-anjorin-logo-white.png';

function App() {
  const navLinkStyle = {
    color: '#00b4db',
    // backgroundColor: '#000000',
    fontWeight: '700',
    fontSize: '16px',
  };
  return (
    <div className='demo-big-content'>
      <Layout /*style={{background: 'url(http://www.getmdl.io/assets/demos/transparent.jpg) center / cover'}}*/>
        <Header
          className='header-color'
          transparent
          title={
            <Link style={{ textDecoration: 'none', color: 'black', display: 'block' }} to='/'>
              <img src={logo} alt='Logo' className='logo-image' />
            </Link>
          }
          style={{ color: 'black' }}
          scroll
        >
          <Navigation>
            <Link to='/about' style={{ color: 'black' }}>
              About Me
            </Link>
            <Link to='/projects' style={{ color: 'black' }}>
              Projects
            </Link>
          </Navigation>
        </Header>
        <Drawer
          title={<img src={logoWhite} alt='Logo' className='logo-image' />}
          style={{ color: '#00b4db', backgroundColor: '#000000', borderColor: '#000000' }}
        >
          <Navigation>
            <Link style={navLinkStyle} to='/'>
              Home
            </Link>
            <Link style={navLinkStyle} to='/about'>
              About Me
            </Link>
            <Link style={navLinkStyle} to='/projects'>
              Projects
            </Link>
          </Navigation>
        </Drawer>
        <Content>
          <div className='page-content' />
          <Main />
        </Content>
      </Layout>
    </div>
  );
}

export default App;
