import React from 'react';

function Cubes() {
  return (
    <>
      <div className='cube'></div>
      <div className='cube'></div>
      <div className='cube'></div>
      <div className='cube'></div>
      <div className='cube'></div>
    </>
  );
}

export default Cubes;
